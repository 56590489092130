import React, {Component} from "react"
import ErrorIndicator from "../error-indicator"

export default class ErrorBoundry extends Component{

    state = {
        hasError: false
    }

    componentDidCatch(e){
        console.log(e)
        this.setState({hasError:true})
    }

    render() {
        if (this.state.hasError){
            return <ErrorIndicator />
        }
        
        return this.props.children
    }
}