import React from "react"

const {
    Provider: ApiServiceProvider,
    Consumer: ApiServiceConsumer,
} = React.createContext();

export {
    ApiServiceProvider,
    ApiServiceConsumer
}